/* ========================================================================== */
/* LOADERS */
/* Loaders components styles */
/* ========================================================================== */

/* Basic loader spinner
 * ========================================================================== */
.loader
{
	margin: $spacing-xs 0;
	text-align: center;
	color: $loader-spinner-color;

	&-message {
		color: $loader-message-color;
	}

	&-large{
		margin-top: 80px
	}

	&-spinner
	{
		@include rectangle($loader-spinner-size);
		animation: $loader-spinner-animation;
		
		&-path
		{
			stroke: $loader-spinner-color;
			stroke-linecap: round;
			animation: $loader-spinner-path-animation;

			button:not(.btn-link) & {
				stroke: #999;
			}
		}
	}

	&.loader-small {
		margin: 0;
		display: inline-block;
		
		.loader-spinner {
			@include rectangle($loader-spinner-size-sm);
			margin: 0.4em;
		}
	}

	&.revert
	{
		color: $loader-spinner-revert-color;

		.loader-spinner-path { stroke: $loader-spinner-revert-color; }
	}
}

/* Loader in spash screen */
.loader-standalone-wrapper {
	height: 100vh;
	
	.loader-standalone {
		transform: translateY(-50%);
		position: relative;
		top: 50%;

		.loader-spinner {
			@include rectangle($loader-spinner-size-xl);
		}
	}
}

/* Loader as overlay
 * ========================================================================== */
/* Base styles */
%loader-overlay
{
	@include flex-center;
	@include position-absolute(0, 0, 0, 0);
	z-index: $page-loader-overlay-zindex;
	margin: 0;
	background: $page-loader-overlay-bg;
}

/* Page loader */
.page-loader
{
	@extend %loader-overlay;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	transition-delay: $page-loader-transition;

	&.is-loading 
	{
		opacity: 1;
		visibility: visible;
	}
}
  
/* Block loader */
.block-loader
{
	position: relative;
	
	.loader 
	{
		@extend %loader-overlay;
		flex-direction: column;
	}
}

/* Input addon loader */
.input-group-append {

	.loader-small {
		.loader-spinner {
			margin: 0;
		}
	}
}

/* Spinners in table */
.cell-with-small-spinner {
	height: 50px;
}
