/* ========================================================================== */
/* <ng-select> */
/* ng-select component styles overload */
/* ========================================================================== */


/* Vars */
/* ========================================================================== */
$ng-select-highlight: $input-focus-border-color !default;
$ng-select-primary-text: $input-color !default;
$ng-select-disabled-color: $input-color !default;
$ng-select-disabled-bg: $input-disabled-bg !default;
$ng-select-border: $input-border-color !default;
$ng-select-border-radius: $input-border-radius !default;
$ng-select-bg: $input-bg !default;
$ng-select-selected: $dropdown-link-active-bg !default;
$ng-select-marked: $dropdown-link-hover-bg !default;
$ng-select-box-shadow: none !default;
$ng-select-placeholder: $input-placeholder-color !default;
$ng-select-height: $input-height !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: $font-size-sm !default;
$ng-select-value-disabled: $gray-450 !default;

$ng-option-bg: $ng-select-bg !default;
$ng-option-color: rgba(0, 0, 0, .87) !default;


/* Common */
/* ========================================================================== */
@mixin ng-select-wrap-text() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select {
    &.ng-select-custom {
        position: relative;
        display: block;
        box-sizing: border-box;

        div,
        input,
        span {
            box-sizing: border-box;
        }

        [hidden] {
            display: none;
        }

        &.ng-select-searchable {
            .ng-select-container .ng-value-container .ng-input {
                opacity: 1;
            }
        }

        &.ng-select-opened .ng-select-container {
            z-index: 1001;
        }

        &.ng-select-disabled {
            .ng-select-container {
                .ng-value-container {

                    .ng-placeholder,
                    .ng-value {
                        user-select: none;
                        cursor: default;
                    }
                }
            }

            .ng-arrow-wrapper {
                cursor: default;
            }
        }

        &.ng-select-filtered {
            .ng-placeholder {
                display: none;
            }
        }

        .ng-select-container {
            color: $input-color;
            cursor: default;
            display: flex;
            outline: none;
            overflow: hidden;
            position: relative;
            width: 100%;

            .ng-value-container {
                display: flex;
                flex: 1;

                .ng-input {
                    opacity: 0;

                    >input {
                        box-sizing: content-box;
                        background: none transparent;
                        border: 0 none;
                        box-shadow: none;
                        outline: none;
                        cursor: default;
                        width: 100%;

                        &::-ms-clear {
                            display: none;
                        }

                        &[readonly] {
                            user-select: none;
                            width: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }

        &.ng-select-single {
            &.ng-select-filtered {
                .ng-select-container .ng-value-container .ng-value {
                    visibility: hidden;
                }
            }

            .ng-select-container {
                .ng-value-container {
                    @include ng-select-wrap-text;

                    .ng-value {
                        @include ng-select-wrap-text;

                        .ng-value-icon {
                            display: none;
                        }
                    }

                    .ng-input {
                        position: absolute;
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }

        &.ng-select-multiple {
            &.ng-select-disabled {
                >.ng-select-container .ng-value-container .ng-value {
                    .ng-value-icon {
                        display: none;
                    }
                }
            }

            .ng-select-container {
                .ng-value-container {
                    flex-wrap: wrap;

                    .ng-placeholder {
                        position: absolute;
                    }

                    .ng-value {
                        white-space: nowrap;

                        &.ng-value-disabled {
                            .ng-value-icon {
                                display: none;
                            }
                        }

                        .ng-value-icon {
                            cursor: pointer;
                        }
                    }

                    .ng-input {
                        flex: 1;
                        z-index: 2;
                    }

                    .ng-placeholder {
                        z-index: 1;
                    }
                }
            }
        }

        .ng-clear-wrapper {
            cursor: pointer;
            position: relative;
            width: 17px;
            user-select: none;

            .ng-clear {
                display: inline-block;
                font-size: 18px;
                line-height: 1;
                pointer-events: none;
            }
        }

        .ng-spinner-loader {
            border-radius: 50%;
            width: 17px;
            height: 17px;
            margin-right: 5px;
            font-size: 10px;
            position: relative;
            text-indent: -9999em;
            border-top: 2px solid rgba(66, 66, 66, 0.2);
            border-right: 2px solid rgba(66, 66, 66, 0.2);
            border-bottom: 2px solid rgba(66, 66, 66, 0.2);
            border-left: 2px solid #424242;
            transform: translateZ(0);
            animation: load8 0.8s infinite linear;

            &:after {
                border-radius: 50%;
                width: 17px;
                height: 17px;
            }

            @-webkit-keyframes load8 {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes load8 {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .ng-arrow-wrapper {
            cursor: pointer;
            position: relative;
            text-align: center;
            user-select: none;

            .ng-arrow {
                &:before {
                    @extend %fa-icon;
                    @extend .fal;
                    content: fa-content($fa-var-angle-down);
                }
            }
        }
    }

    .ng-dropdown-panel {
        box-sizing: border-box;
        position: absolute;
        opacity: 0;
        width: 100%;
        z-index: 1050;
        -webkit-overflow-scrolling: touch;

        .ng-dropdown-panel-items {
            display: block;
            height: auto;
            box-sizing: border-box;
            max-height: 240px;
            overflow-y: auto;

            .ng-optgroup {
                @include ng-select-wrap-text();
            }

            .ng-option {
                box-sizing: border-box;
                cursor: pointer;
                display: block;
                @include ng-select-wrap-text();

                .highlighted {
                    text-decoration: underline;
                }

                &.disabled {
                    cursor: default;
                }
            }
        }

        .scroll-host {
            overflow: hidden;
            overflow-y: auto;
            position: relative;
            display: block;
            -webkit-overflow-scrolling: touch;
        }

        .scrollable-content {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .total-padding {
            width: 1px;
            opacity: 0;
        }
    }
}



/* Theming */
/* ========================================================================== */
.ng-select {
    &.ng-select-custom {

        &.is-invalid .ng-select-container {
            border-color: $form-feedback-invalid-color !important;
        }

        &.ng-select-opened {
            >.ng-select-container {
                background: $ng-select-bg;
                border-color: $ng-select-border;

                &:hover {
                    box-shadow: none;
                }

                .ng-arrow:before {
                    content: fa-content($fa-var-angle-up);
                }
            }

            &.ng-select-bottom {
                >.ng-select-container {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &.ng-select-top {
                >.ng-select-container {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }
            }
        }

        &.ng-select-focused {
            &:not(.ng-select-opened)>.ng-select-container {
                border-color: $ng-select-highlight;
            }
        }

        &.ng-select-disabled {
            >.ng-select-container {
                background-color: $ng-select-disabled-bg;
            }
        }

        .ng-has-value .ng-placeholder {
            display: none;
        }

        .ng-select-container {
            background-color: $ng-select-bg;
            border-radius: $ng-select-border-radius;
            border: 1px solid $ng-select-border;
            min-height: $ng-select-height;
            align-items: center;

            .ng-value-container {
                align-items: center;
                padding-left: $ng-select-value-padding-left;

                .ng-placeholder {
                    color: $ng-select-placeholder;
                }
            }
        }

        &.ng-select-single {
            .ng-select-container {
                height: $ng-select-height;

                .ng-value-container {
                    .ng-input {
                        top: 5px;
                        left: 0;
                        padding-left: $ng-select-value-padding-left;
                        padding-right: 50px;
                    }
                }
            }
        }

        &.ng-select-multiple {
            &.ng-select-disabled {
                >.ng-select-container .ng-value-container .ng-value {
                    background-color: $ng-select-value-disabled;
                }
            }

            .ng-select-container {
                .ng-value-container {
                    padding-top: 5px;
                    padding-left: 7px;

                    .ng-value {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        line-height: 1;
                        font-size: $ng-select-value-font-size;
                        margin-bottom: 5px;
                        background-color: $primary;
                        color: $white;
                        border-radius: 2px;
                        margin-right: 5px;

                        &.ng-value-disabled {
                            background: $ng-select-value-disabled;

                            .ng-value-label {
                                padding-left: 5px;
                            }
                        }

                        .ng-value-label {
                            padding: 3px 5px;
                        }

                        .ng-value-icon {
                            padding: 3px 5px 3px 0;
                            font-size: $font-size-base;
                        }
                    }

                    .ng-input {
                        padding: 0 0 3px 3px;
                    }

                    .ng-placeholder {
                        top: 5px;
                        padding-bottom: 5px;
                        padding-left: 3px;
                    }
                }
            }
        }

        .ng-clear-wrapper {
            color: darken($ng-select-border, 20);

            &:hover .ng-clear {
                color: $red;
            }
        }

        .ng-spinner-zone {
            padding: 5px 5px 0 0;
        }

        .ng-arrow-wrapper {
            width: 18px;

            .ng-arrow {
                padding-right: 10px;
            }
        }
    }

    .ng-dropdown-panel {
        background-color: $ng-select-bg;
        border: 1px solid $ng-select-border;
        left: 0;

        &.ng-select-bottom {
            top: 100%;
            border-bottom-right-radius: $ng-select-border-radius;
            border-bottom-left-radius: $ng-select-border-radius;
            border-top-color: lighten($ng-select-border, 10);
            margin-top: -1px;

            .ng-dropdown-panel-items {
                .ng-option {
                    &:last-child {
                        border-bottom-right-radius: $ng-select-border-radius;
                        border-bottom-left-radius: $ng-select-border-radius;
                    }
                }
            }
        }

        &.ng-select-top {
            bottom: 100%;
            border-top-right-radius: $ng-select-border-radius;
            border-top-left-radius: $ng-select-border-radius;
            border-bottom-color: lighten($ng-select-border, 10);
            margin-bottom: -1px;

            .ng-dropdown-panel-items {
                .ng-option {
                    &:first-child {
                        border-top-right-radius: $ng-select-border-radius;
                        border-top-left-radius: $ng-select-border-radius;
                    }
                }
            }
        }

        .ng-dropdown-header {
            border-bottom: 1px solid $ng-select-border;
            padding: 5px 7px;
        }

        .ng-dropdown-footer {
            border-top: 1px solid $ng-select-border;
            padding: 5px 7px;
        }

        .ng-dropdown-panel-items {
            .ng-optgroup {
                user-select: none;
                padding: 8px 10px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;

                &.ng-option-disabled {
                    cursor: default;
                }

                &.ng-option-marked {
                    background-color: $ng-select-marked;
                }

                &.ng-option-selected,
                &.ng-option-selected.ng-option-marked {
                    background-color: $ng-select-selected;
                    font-weight: 600;
                }
            }

            .ng-option {
                background-color: $ng-option-bg;
                color: $ng-option-color;
                padding: 8px 10px;

                &.ng-option-selected,
                &.ng-option-selected.ng-option-marked {
                    color: $ng-select-primary-text;
                    background-color: $ng-select-selected;

                    .ng-option-label {
                        font-weight: 600;
                    }
                }

                &.ng-option-marked {
                    background-color: $ng-select-marked;
                    color: $ng-select-primary-text;
                }

                &.ng-option-disabled {
                    color: lighten($ng-select-primary-text, 60);
                }

                &.ng-option-child {
                    padding-left: 22px;
                }

                .ng-tag-label {
                    font-size: 80%;
                    font-weight: 400;
                    padding-right: 5px;
                }
            }
        }
	}
	
	&.ng-select-custom-alt {
		.ng-select-container {
            background-color: $input-alt-bg;
            border-color: $input-alt-border-color;
		}
	}
}