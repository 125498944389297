@use "sass:color";

/* ========================================================================== */
/* COMPONENTS */
/* Components vars */
/* ========================================================================== */

/* Buttons
 * ========================================================================== */
$btn-link-color: 					$gray-600 !default;
$btn-link-font-size: 				$font-size-sm !default;

$btn-filter-padding:				$spacing-xs !default;
$btn-filter-radius:					$border-radius !default;
$btn-filter-active-bg:				$primary !default;

$btn-switch-bg-color:				$gray-450 !default;
$btn-switch-checked-bg-color:		$success !default;
$btn-switch-pending-bg-color:		$warning !default;
$btn-switch-border-radius:			7px !default;
$btn-switch-height:					16px !default;
$btn-switch-width:					25px !default;
$btn-switch-label-color:			$gray-450 !default;
$btn-switch-toggle-color:			$white !default;
$btn-switch-toggle-size:			10px !default;
$btn-switch-toggle-position:		3px !default;
$btn-switch-toggle-translateX:		9px !default;


/* Forms
 * ========================================================================== */
$input-alt-bg: 						$gray-100 !default;
$input-alt-color: 					$input-color !default;
$input-alt-border-color: 			$input-border-color !default;
$input-alt-focus-bg: 				color.adjust($input-alt-bg, $lightness: -5%) !default;
$input-alt-focus-border-color: 		color.adjust($input-alt-border-color, $lightness: -10%) !default;
$input-alt-focus-color: 			$input-alt-color !default;

$input-datepicker-w: 				210px !default;
$input-date-expression-w: 			80px !default;

$form-label-color: 					$gray-450 !default;
$form-label-color-dark: 			$gray-700 !default;
$form-label-font-size-px: 			13px !default;
$form-label-font-size: 				px-to-rem($form-label-font-size-px) !default;

$form-content-bg: 					$gray-100 !default;
$form-content-border: 				1px solid $gray-200 !default;
$form-content-padding: 				$spacing-md !default;



/* Checkbox / Radio
 * ========================================================================== */
$checkbox-radio-size:				18px !default;
$checkbox-radio-font-size:			px-to-rem(14px) !default;
$checkbox-radio-bg:					$white !default;
$checkbox-radio-disabled-bg:        $gray-200 !default;
$checkbox-radio-color:				$gray-300 !default;
$checkbox-radio-active-color:		$primary !default;
$checkbox-radio-label-offset:		$checkbox-radio-size + 5px !default;
$checkbox-radius:					5px !default;
$radio-radius:						50% !default;


/* Tabs
 * ========================================================================== */
$tabs-font-size:  					$font-size-md !default;


/* Control tabs
* ========================================================================== */
$control-tabs-color:				$secondary !default;
$control-tabs-bg:					$white!default;
$control-tabs-border:				1px solid $gray-500 !default;
$control-tabs-bullet-size:			30px !default;
$control-tabs-step-font:			$font-size-sm !default;
$control-tabs-status-color:			$secondary !default;
$control-tabs-opacity:				.3 !default;
$control-tabs-active-opacity:		1 !default;


 /* Dropdown
  * ========================================================================== */
$custom-dropdown-width:				120px;
$custom-dropdown-padding:			8px $spacing-xs;
$custom-dropdown-border:			1px solid $input-alt-border-color;


/* Table
 * ========================================================================== */
$table-border-style: 				$table-border-width solid $table-border-color !default;
$table-active-border: 				2px solid rgba($secondary, .6) !default;
$table-sort-icon-opacity:			.3 !default;

$table-dragging-placeholder-bg: 	$gray-200 !default;

$table-nested-bg-color:				$gray-150 !default;
$table-nested-transition-open:		max-height .8s linear !default;
$table-nested-transition-close:		max-height .4s ease-out !default;
$table-nested-transition-height:	800px !default;

$table-col-index-width:				30px !default;
$table-col-id-width:				150px !default;
$table-col-id-lg-width:				190px !default;
$table-col-id-xl-width:				240px !default;
$table-col-name-width:				auto !default;
$table-col-name-fixed-width:		200px !default;
$table-col-legalIdentifier-width:	200px !default;
$table-col-amount-width:			130px !default;
$table-col-small-amount-width:		50px !default;
$table-col-large-amount-width:		190px !default;
$table-col-small-status-width:		100px !default;
$table-col-status-width:			190px !default;
$table-col-btn-expand-width:		70px !default;
$table-col-btn-actions-width:		60px !default;
$table-col-date-width:				150px !default;
$table-col-order-width:				60px !default;
$table-col-checkbox-width:			70px !default;
$table-col-selection-width:			100px !default;
$table-col-mapping-width:			200px !default;
$table-col-error-msg-width:			auto !default;
$table-col-error-description-width:	280px !default;
$table-col-file-width:				100px !default;
$table-col-type-width:				150px !default;
$table-col-small-type-width:		150px !default;
$table-col-small-comment-width:		100px !default;
$table-col-boolean-width:			80px !default;
$table-col-username-width:			200px !default;
$table-col-city-width:				200px !default;
$table-col-filename-width:			200px !default;
$table-col-preview-width:			150px !default;
$table-col-dso-width:				70px !default;

									// width, align, isNowrap, vertical-align
$table-col-sizes: 					(
										index: ($table-col-index-width, center, false, null),
										id: ($table-col-id-width, null, true, null),
										id-lg: ($table-col-id-lg-width, null, true, null),
										id-xl: ($table-col-id-xl-width, null, true, null),
										name: ($table-col-name-width, null, false, null),
										name-fixed: ($table-col-name-fixed-width, null, false, null),
										amount: ($table-col-amount-width, null, true, null),
										large-amount: ($table-col-large-amount-width, null, true, null),
										small-amount: ($table-col-small-amount-width, null, false, null),
										status: ($table-col-status-width, null, true, null),
										small-status: ($table-col-small-status-width, null, true, null),
										legalIdentifier: ($table-col-legalIdentifier-width, null, true, null),
										btn-expand: ($table-col-btn-expand-width, center, false, null),
										date: ($table-col-date-width, null, true, null),
										btn-actions: ($table-col-btn-actions-width, right, true, null),
										order: ($table-col-order-width, center, true, null),
										checkbox: ($table-col-checkbox-width, center, false, null),
										selection: ($table-col-selection-width, center, false, null),
										mapping: ($table-col-mapping-width, left, false, null),
										error-msg: ($table-col-error-msg-width, left, false, null),
										error-description: ($table-col-error-description-width, left, false, null),
										file: ($table-col-file-width, center, false, null),
										type: ($table-col-type-width, null, false, null),
										small-type: ($table-col-small-type-width, null, false, null),
										small-comment: ($table-col-small-comment-width, null, false, null),
										boolean: ($table-col-boolean-width, null, false, null),
										username: ($table-col-username-width, null, false, null),
										city: ($table-col-city-width, null, false, null),
										filename: ($table-col-filename-width, null, false, null),
										preview: ($table-col-preview-width, null, false, null)
									) !default;


$table-flex-width:					600px !default;
$table-flex-font-size:				px-to-rem(13px) !default;
$table-flex-head-color:				$gray-450 !default;
$table-flex-body-color:				$gray-700 !default;
$table-flex-head-padding:			$spacing-xs 25px !default;
$table-flex-body-padding:			15px 25px !default;
$table-flex-row-margin:				0 0 $spacing-xs 0 !default;
$table-flex-row-border:				1px solid $gray-550 !default;


$table-light-width: 				100% !default;
$table-light-spacing: 				0 $spacing-xs !default;
$table-light-font-size: 			px-to-rem(13px) !default;
$table-light-thead-padding: 		0 25px !default;
$table-light-thead-color: 			$gray-450 !default;
$table-light-tbody-padding: 		15px 25px !default;
$table-light-tbody-color: 			$gray-700 !default;
$table-light-border-color: 			$gray-550 !default;



$table-spreadsheet-th-bg: 			$gray-100 !default;
$table-spreadsheet-th-bg-valid: 	color.adjust($table-spreadsheet-th-bg, $lightness: -7%) !default;
$table-spreadsheet-th-border: 		$table-border-width solid #d9dee8 !default;
$table-spreadsheet-td-border: 		$table-border-width solid $table-border-color !default;
$table-spreadsheet-td-bg: 			$white !default;
$table-spreadsheet-td-accent-bg: 	rgba($table-spreadsheet-th-bg, .3) !default;

$table-col-fixed-width:				200px !default;

 /* Paging
  * ========================================================================== */
$paging-content-padding:			$grid-gutter-width 30px !default;


 /* Chips
  * ========================================================================== */
$chip-border-radius:				25px !default;
$chip-border:						$border-width solid $gray-250 !default;
$chip-height:						30px !default;
$chip-spacing:						4px !default;
$chip-font-size:					px-to-rem(13px) !default;
$chip-padding-y:					7px !default;
$chip-padding-x:					$spacing-xs !default;
$chip-btn-color:					$gray-300 !default;
$chip-hover-color:					$gray-100 !default;
$chip-content-width:				500px !default;

$chip-color:						$gray-600 !default;
$chip-bg-color:						#f1f1f1 !default;
$chip-color-seller:					#e78200 !default;
$chip-color-bg-seller:				#fff2e1 !default;
$chip-color-debtor:					#a1008f !default;
$chip-color-bg-debtor:				#ffe6fc !default;
$chip-color-accounting:				#99c418 !default;
$chip-color-bg-accounting:			#f3f9e3 !default;
$chip-color-multi:					#e6b800 !default;
$chip-color-bg-multi:				#fff0b3 !default;
$chip-color-saved:					#97a1bf !default;
$chip-color-bg-saved:				#eff1f7 !default;

$chip-active-bg:					$primary !default;
$chip-active-border-color:			$primary-dark !default;
$chip-active-color:					$white !default;

$chip-color-type: (
									// text color, bg color, border color
									seller: ($chip-color-seller, $chip-color-bg-seller, $chip-color-seller),
									debtor: ($chip-color-debtor, $chip-color-bg-debtor, $chip-color-debtor),
									accounting: ($chip-color-accounting, $chip-color-bg-accounting, $chip-color-accounting),
									multi: ($chip-color-multi, $chip-color-bg-multi, $chip-color-multi),
									saved: ($chip-color-saved, $chip-color-bg-saved, $chip-color-saved),
									active: ($chip-active-color, $chip-active-bg, $chip-active-border-color)
								) !default;




 /* Collapsible block
  * ========================================================================== */
$collapse-block-transition:			1s !default;
$collapse-block-height:				1000px !default;


 /* Filters
  * ========================================================================== */
$filters-padding: 					$paging-content-padding !default;
$filter-spacing:					$spacing-xs !default;

$active-filters-label-color: 		$gray-300 !default;
$active-filters-label-padding-top:	$chip-spacing + $chip-padding-y !default;


$filters-builder-border-color:		#cdd3e5 !default;
$filters-builder-bg:				$gray-200 !default;
$filters-builder-max-height:		2000px !default; // TODO: Use angular animations
$filters-builder-transition:		all .3s ease !default;
$filters-builder-input-width-xs:	110px !default;
$filters-builder-input-width-sm:	160px !default;
$filters-builder-row-control-w: 	20% !default;
$filters-builder-row-action-w: 		60px !default;

$filters-action-button-size:			25px !default;
$filters-action-button-font-size:		px-to-rem(14px) !default;
$filters-action-button-create-bg:		$secondary !default;
$filters-action-button-create-color:	$white !default;
$filters-action-button-add-bg:			$success !default;
$filters-action-button-add-color:		$white !default;
$filters-action-button-close-bg:		$gray-800 !default;
$filters-action-button-close-color:		$white !default;
$filters-action-button-remove-bg:		$danger !default;
$filters-action-button-remove-color:	$white !default;
$filters-action-button-height:			$chip-height + $chip-spacing * 2 !default;

$filters-action-button-triangle-size:	10px !default;
$filters-action-button-triangle-top:	48px !default;
$filters-action-button-triangle-top-upper:	61px !default;
$filters-action-button-triangle-left:	7px !default;


 /* Cards
  * ========================================================================== */
$card-item-padding:					15px $spacing !default;
$card-item-bg-color:				$white !default;
$card-item-border:					$border-width solid $gray-350 !default;
$card-item-border-radius:			$border-radius !default;
$card-item-date-color:				$gray-300 !default;
$card-item-toggle-color:			$gray-450 !default;
$card-item-toggle-hover-color:		$secondary !default;

 /* Tasks
  * ========================================================================== */
$task-status-new-color:				$secondary !default;
$task-status-progress-color:		$warning !default;
$task-status-done-color:			$disabled !default;

$task-item-bg-color:				$gray-100 !default;
$task-item-disabled-bg-color:		$gray-200 !default;
$task-item-bar-position:			8px !default;
$task-item-bar-size:				6px !default;
$task-item-padding-left:			$spacing-md !default;
$task-item-body-color:				$gray-450 !default;

$taskboard-filters-y:				$main-padding-y !default;
$taskboard-filters-x:				$main-padding-x !default;
$taskboard-filters-bottom:			$spacing !default;


 /* Comments
  * ========================================================================== */
$comment-label-color:				$gray-450 !default;


 /* Popover
  * ========================================================================== */
$popover-margin-top:				15px !default;


/* Notifications
 * ========================================================================== */
$notifications-top:					$top-bar-h + 1 !default;
$notification-w:					400px !default;
$notifications-bg:					$white !default;
$notifications-filters-bg:			$gray-100 !default;
$notifications-filters-active-bg:	$btn-filter-active-bg !default;
$notifications-head-padding:		15px $spacing !default;
$notifications-shadow:				-1px 2px 3px 0 rgba(0, 0, 0, 0.15) !default;
$notifications-transition:			$transition-all !default;

$notification-item-border: 			$border-width solid	$gray-550 !default;
$notification-item-name-margin:		5px !default;

$notifications-zindex:				$zindex-tooltip !default;



/* KPI */
/* ========================================================================== */
$kpi-title-color:		$white !default;
$kpi-title-size:		$font-size-xl !default;
$kpi-label-size:		$font-size-md !default;
$kpi-label-size-lg:		$font-size-base !default;
$kpi-label-color:		rgba($black, .45) !default;
$kpi-value-size:		px-to-rem(22px) !default;

$kpi-types:			(
						type-1: ($cadet-blue-light, $cadet-blue),
						type-1-alt: ($cadet-blue-extra-light, $cadet-blue),
						type-2: (#1b6dcc, $kpi-label-color),
						type-3: ($secondary, $kpi-label-color),
						type-4: ($orange, $kpi-label-color),
						type-4-alt: ($orange-light, $kpi-label-color),
						type-5: ($red, $kpi-label-color),
						type-6: ($cadet-blue, $kpi-label-color),
						type-7: (#9ed0b4, $kpi-label-color),
						type-8: (#579deb, $kpi-label-color),

						type-primary: ($primary, $kpi-label-color),
						type-secondary: ($secondary, $kpi-label-color),
						type-warning: ($orange, $kpi-label-color),
						type-danger: ($red, $kpi-label-color),
						type-danger-dark: ($red, $kpi-label-color),
						type-success: ($green, $kpi-label-color)
					) !default;

$kpi-item-spacing: 		$spacing-xs !default;

/* Files list */
/* ========================================================================== */
$files-list-border: 						1px dashed $border-color !default;
$files-list-empty-icon-color: 				$primary !default;
$files-list-empty-icon-size: 				48px !default;
$files-list-item-w: 						150px !default;
$files-list-item-padding: 					$spacing !default;
$files-list-item-icon-color: 				$primary !default;
$files-list-item-icon-delete-color: 		$white !default;
$files-list-item-icon-delete-bg: 			$primary !default;
$files-list-item-icon-add-color:	 		$primary !default;
$files-list-item-icon-add-bg: 				$white !default;

$file-upload-dragover-opacity: 				.5 !default;
$file-upload-dragover-outline: 				2px dashed $primary !default;

/* Settings list */
/* ========================================================================== */
$settings-list-border-color: 				$gray-200 !default;
$settings-list-label-w: 					25% !default;

/* Import */
/* ========================================================================== */
$import-files-container:                    transparent !default;
$import-files-container-notempty:           $white !default;
