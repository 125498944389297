/* ========================================================================== */
/* KPIs */
/* KPIs blocks */
/* ========================================================================== */

/* Structure
 * ========================================================================== */
.kpi-list
{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: $spacing-xs;

	// Horizontal
	&-h
	{
		margin-bottom: $spacing-md;
	}

	@include media-breakpoint-up(lg)
	{
		// Horizontal
		&-h
		{
			flex-wrap: nowrap;
			margin-left: -$kpi-item-spacing;
			margin-right: -$kpi-item-spacing;
		}

		// Vertical
		&-v
		{
			display: block;
		}
	}
}

.kpi-contents {
	display: contents;
}

.kpi-list-v.kpi-list-with-variations .kpi-item {
	padding: 20px 0px 20px 10px;
}

/* Items
 * ========================================================================== */
.kpi-item
{
	position: relative;
	$context: &;

	flex-basis: calc(50% - 5px);
	padding: $spacing;
	margin-bottom: $kpi-item-spacing;
	border-radius: $border-radius;


	.kpi-list-h-full &
	{
		flex: 1;
	}

	.kpi-list-3-items &
	{
		flex-grow: calc(0.3333) !important;
	}

	&-title
	{
		color: $kpi-title-color;
		font-size: $kpi-title-size;
	}

	.label
	{
		@include text-semi-bold;
		text-transform: uppercase;
		font-size: $kpi-label-size;
	}

	.value
	{
		color: $white;
		font-size: $kpi-value-size;
		line-height: $line-height-lg;
		word-break: break-all;
	}

	.value-small
	{
		font-size: 1rem;
	}

	&-with-ellipsis .value {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&-data
	{
		& + &
		{
			margin-top: $spacing-xs;
		}
	}

	@each $type, $colors in $kpi-types
	{
		&-#{$type}
		{
			background: nth($colors, 1);
			color: nth($colors, 2);
		}
	}
	

	@include media-breakpoint-up(lg)
	{
		.kpi-list-v &
		{
			padding: $spacing 30px;
		}

		.kpi-list-h &
		{
			flex: 1;
			margin: 0 $kpi-item-spacing;

			.label { font-size: $kpi-label-size-lg; }
		}

		.kpi-list-h-full &
		{
			&-data
			{
				flex-basis: ratio-to-percentage(0.3333);
				margin-bottom: $spacing-xs;
				margin-top: 0;

				&-set
				{
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	}

	@include media-breakpoint-up(xl)
	{
		.kpi-list-h &
		{
			padding: $spacing 30px;
		}
	}
	
}

.kpi-item-home {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	
	.kpi-icon {
		align-self: center;
		font-size: 40px;
	}

	button {
		border: none;
    	background: none;
		font-size: 20px;
		color: $kpi-label-color;
		outline: none;
	}
}

.kpi-double-item {
	display: flex;
    width: 400px;
	justify-content: space-between;
	
	.kpi-item {
		width: 195px;
	}
}

/****
 * Interactive kpis
 */
.kpi-list.kpi-list-interactive {

	.kpi-item {
		cursor: pointer;
		text-align: left;
		color: rgba(0, 0, 0, 0.45);
	}

	.is-inactive {
		opacity: .2;
	}
}
