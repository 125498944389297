/* ========================================================================== */
/* CONTENT ELEMENTS */
/* Layout's content's elements */
/* ========================================================================== */

.tenant-title .page-header span {
	color: $tenant-title-color;
}

/* Content block
 * ========================================================================== */
.content-block
{
	background: $content-block-bg;
	box-shadow: $content-block-shadow;
	border-radius: $border-radius;

	& + &,
	&.has-margin 
	{
		margin-top: $spacing;
	}

	&.has-sidebar,
	&-splitted
	{
		display: flex;
		flex-wrap: wrap;
	}

	&.has-padding,
	&-main.has-padding
	{
		padding: $content-block-padding;
	}

	&.has-half-padding,
	&-main.has-half-padding
	{
		padding: $content-block-padding * .5;
	}

	&.has-border
	{
		margin-bottom: $spacing-xs;
		border: $content-block-border;
	}

	&-negative
	{
		margin: $content-block-negative-margin;
	}

	&-actions
	{
		padding: $filters-padding;
	}

	&-main
	{
		flex: 1;
		margin-bottom: $spacing;

		@include media-breakpoint-up(lg) 
		{
			margin-bottom: 0;
		}
	}

	&-sidebar
	{
		@include media-breakpoint-up(lg) 
		{
			flex-shrink: 0;
			flex-grow: 0;
			width: $content-block-sidebar-w;
			margin-left: $spacing-xl;
		}
	}

	&-full
	{
		margin-top: $spacing-md;

		@include media-breakpoint-up(lg) 
		{
			flex: auto;
			width: 100%;
		}
	}

	&-header
	{
		@include flex-spacebetween(center);
		margin-bottom: $spacing-md;

		h2, h3 
		{ 
			margin-bottom: 0;
		}
	}
}

.has-content-block-negative.block-loader
{
	.loader 
	{
		left: -$content-block-padding;
		right: -$content-block-padding;
	}
}

/* Content structure
 * ========================================================================== */
.content-control
{
	&-sidebar
	{
		margin: $content-control-sidebar-margin;
		background: $content-control-bg;
	}

	&-block
	{
		padding: 30px $spacing-md;

		&.is-not-last
		{
			@include border-bottom($content-border-color);
		}
	}

	@include media-breakpoint-up(xl)
	{
		&-main
		{
			width: $content-control-main-xl-width;
		}

		&-sidebar
		{
			@include position-absolute(0, 0, 0);
			width: $content-control-sidebar-xl-width;
			margin: 0;
		}
	}
}

@include media-breakpoint-up(xl) 
{
	.kyc-control-page,
	.transfer-control-page
	{
		#main
		{
			background: $content-control-sidebar-xl-bg;
		}
	}	
}


/* Content info text
 * ========================================================================== */
.content-info
{
	color: $gray-700;
	display: flex;
	margin: 0 0 $spacing-md;

	&:before 
	{
		@extend %fa-icon;
		@extend .fal;    
		content: fa-content($fa-var-info-circle);
		margin: 3px $spacing-xs 0 0;
		font-size: $font-size-xl;
	}
}

/* Other elements used everywhere
 * ========================================================================== */
.no-data 
{
	@include text-italic;
	color: $gray-500;
}

/* Page loaders
 * ========================================================================== */
body .block-loader .loader, body .page-loader {
	background: $loader-block-bg
}

.cursor-pointer {
   cursor: pointer;
}