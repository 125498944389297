/* ========================================================================== */
/* ICONFONT */
/* Custom Iconfont styles */
/* ========================================================================== */

.fac
{
	@extend %fa-icon;
	font-family: 'iconfont';
	font-weight: normal;
}


	$fa-var-custom-cash: \EA01;

	@mixin fa-custom-cash { content: fa-content($fa-var-custom-cash); }

	.fa-custom-cash:before { @include fa-custom-cash; }

	$fa-var-custom-clipboard: \EA02;

	@mixin fa-custom-clipboard { content: fa-content($fa-var-custom-clipboard); }

	.fa-custom-clipboard:before { @include fa-custom-clipboard; }

	$fa-var-custom-home: \EA03;

	@mixin fa-custom-home { content: fa-content($fa-var-custom-home); }

	.fa-custom-home:before { @include fa-custom-home; }

	$fa-var-custom-paperclip: \EA04;

	@mixin fa-custom-paperclip { content: fa-content($fa-var-custom-paperclip); }

	.fa-custom-paperclip:before { @include fa-custom-paperclip; }

	$fa-var-custom-portfolio: \EA05;

	@mixin fa-custom-portfolio { content: fa-content($fa-var-custom-portfolio); }

	.fa-custom-portfolio:before { @include fa-custom-portfolio; }

	$fa-var-custom-user: \EA06;

	@mixin fa-custom-user { content: fa-content($fa-var-custom-user); }

	.fa-custom-user:before { @include fa-custom-user; }
