/* ========================================================================== */
/* COLLAPSIBLE */
/* Collapsible component styles */
/* ========================================================================== */


.collapsible-block 
{
	$context: &;

	&-title
	{
		position: relative;
		cursor: pointer;

		.content-block &
		{
			margin-bottom: $spacing-md;
		}

		.content-block-low-margin &
		{
			margin-bottom: $spacing-xs;
		}

		&:after
		{
			@extend %fa-icon;
			@extend .far;
			@include pos-center-y;
			content: fa-content($fa-var-angle-down);
			right: 5px;
			font-size: 1em;
		}
	}

	&.is-opened
	{
		#{$context}-title:after
		{
			content: fa-content($fa-var-angle-up);
		}

		#{$context}-content
		{
			// Avoid partly visible elements (like dropdowns) due to animation "overflow: hidden"  
			overflow: visible !important;
		}
	}
}
