/* ========================================================================== */
/* GLOBAL */
/* Basic elements styles */
/* ========================================================================== */

/* Generic markups
 * ========================================================================== */

html
{
	// Forces font-size, avoiding user custom preferences to break the UI
	font-size: $font-size-base-px;
	letter-spacing: $letter-spacing-base;
}

body
{
	overflow: hidden;
}

ul
{
	@include list-reset;
}

button
{
	cursor: pointer;
	letter-spacing: $letter-spacing-base;
}

h1, h2, h3
{
	color: $titles-color;

	& > span
	{
		@include align-inline-block;
		padding-right: 8px;
	}

	& > small
	{
		@include align-inline-block;
		color: $gray-300;
	}
}

dt
{
	color: $gray-500;
	text-transform: uppercase;
	font-size: $font-size-sm;
}

dd
{
	@include text-semi-bold;
	margin-bottom: 0;
}

textarea
{
	resize: vertical;

	&:not([rows])
	{
		min-height: 100px;
		resize: none;
	}
}

[help-token],[ctx-help-token] {
	border-bottom: 1px dashed;
	cursor: help;
}
