/* ========================================================================== */
/* CARDS */
/* Cards styles */
/* ========================================================================== */

/* Structure
 * ========================================================================== */
@mixin card-base (
	$border: $card-item-border,
	$background: $card-item-bg-color,
	$padding: $card-item-padding,
    $border-radius: $card-item-border-radius
)
{
	border: $border;
    border-radius: $border-radius;
	background: $background;
	padding: $padding;
}

%card-base
{
    @include card-base;
}

.card-item
{
	$context: &;

    @extend %card-base;
    margin-bottom: $spacing-xs;
	position: relative;

	&-head
	{
        @include flex-spacebetween(top);
        @include text-semi-bold;
        margin-bottom: 5px;
    }

	&-body
	{
        display: flex;
        align-items: center;
		flex-wrap: wrap;

		&-more
		{
			margin: $spacing-xs 0 0;
		}
    }

	&-date
	{
        flex-shrink: 0;
        color: $card-item-date-color;
	}

	&-toggle
	{
		@extend .btn-reset;
		@include position-absolute(null, $spacing-xs, 5px);
		color: $card-item-toggle-color;
		font-size: $font-size-md;
		padding: 3px $spacing-xs;

		&:before
		{
			@extend %fa-icon;
			@extend .far;
			content: fa-content($fa-var-angle-up);
		}

		&:hover
		{
			color: $card-item-toggle-hover-color;
		}
	}

	&.is-collapsed
	{
		#{$context}-toggle:before
		{
			content: fa-content($fa-var-angle-down);
		}
	}
}

/* Cards Slider
 * ========================================================================== */
.cards-slider
{
	&-header
	{
        @include flex-spacebetween(center);
	}

	&-actions {

	}

	&-controls
	{
        margin-left: $spacing;

		button
		{
			@extend .btn-reset;

			&:before
			{
				@extend %fa-icon;
				@extend .far;
				font-size: $font-size-lg;
			}
		}

		.btn-prev
		{
			&:before
			{
				content: fa-content($fa-var-angle-left);
			}
		}

		.btn-next
		{
			margin-left: $spacing-xs;

			&:before
			{
				content: fa-content($fa-var-angle-right);
			}
		}
	}
}
