/* ========================================================================== */
/* USER */
/* USER component styles */
/* ========================================================================== */


.user-avatar
{
	$context: &;

	display: flex;
	align-items: center;
	
	&-img
	{
		@include flex-center;
		flex-shrink: 0;
		line-height: 1;

		& + #{$context}-name
		{
			margin-left: 5px;
		}
	}

	&-img
	{
		@include circle;
		text-transform: uppercase;
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		border: 1px solid #55555512;
	}

	&-name
	{
		color: $user-avatar-name-color;
		text-transform: none;
	}

	@each $type, $sizes in $user-avatar-sizes 
	{
		&-#{$type}
		{
			.user-avatar-img
			{
				@include rectangle(nth($sizes, 1));
				font-size: px-to-rem(nth($sizes, 2));
			}
		}
	}

	@each $type, $colors in $user-avatar-colors 
	{
		&-#{$type}
		{
			.user-avatar-img, .user-avatar-debtor,
			.user-avatar-debtor + .user-avatar-name
			{
				background: nth($colors, 1);
				color: nth($colors, 2);
			}
		}
	}
}

.user-badge-level {
	&-Gold {
		color: #e2b01a !important;
	}

	&-Silver {
		color: #767676 !important;
	}

	&-Bronze {
		color: #da8027 !important;
	}

	&-None {
		color: #CCC !important;
		opacity: 0.3;
	}
}

user-achievement {
	font-size: 2em;
}
#top-bar user-achievement {
	font-size: 2.6em;
}

user-achievement:nth-child(1) .effect-shine, user-achievement:nth-child(1) .effect-shine-hover { animation-delay: 100ms; }
user-achievement:nth-child(2) .effect-shine, user-achievement:nth-child(2) .effect-shine-hover { animation-delay: 200ms; }
user-achievement:nth-child(3) .effect-shine, user-achievement:nth-child(3) .effect-shine-hover { animation-delay: 300ms; }
user-achievement:nth-child(4) .effect-shine, user-achievement:nth-child(4) .effect-shine-hover { animation-delay: 400ms; }
user-achievement:nth-child(5) .effect-shine, user-achievement:nth-child(5) .effect-shine-hover { animation-delay: 500ms; }
user-achievement:nth-child(6) .effect-shine, user-achievement:nth-child(6) .effect-shine-hover { animation-delay: 600ms; }
user-achievement:nth-child(7) .effect-shine, user-achievement:nth-child(7) .effect-shine-hover { animation-delay: 700ms; }
user-achievement:nth-child(8) .effect-shine, user-achievement:nth-child(8) .effect-shine-hover { animation-delay: 800ms; }
user-achievement:nth-child(9) .effect-shine, user-achievement:nth-child(9) .effect-shine-hover { animation-delay: 900ms; }


.pop-achievement-name {
	font-size: 1.5em;
}

.user-profile-text {
	max-width: 300px;
}