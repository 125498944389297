@use "sass:meta";
@use "sass:math";

/* ========================================================================== */
/* FUNCTIONS */
/* SASS Functions */
/* ========================================================================== */

/* Units
 * ========================================================================== */
@function strip-unit($number)
{
	@if meta.type-of($number) == 'number' and not math.is-unitless($number)
	{
		@return calc($number / ($number * 0 + 1px));
	}

	@return $number;
}

$font-size-base-px:	14px !default;

@function px-to-rem($target, $base: $font-size-base-px)
{
	@return calc(strip-unit($target) / strip-unit($base)) * 1rem;
}

@function ratio-to-percentage($ratio: 1)
{
	@return #{($ratio * 100) + '%'};
}