@use "sass:color";
@use "sass:list";

/* ========================================================================== */
/* CHIPS */
/* Chips component styles */
/* ========================================================================== */

.chip 
{
	display: flex;
	align-items: center;
	height: $chip-height;
	border: $chip-border;
	border-radius: $chip-border-radius;
	margin: $chip-spacing;
	background: $chip-bg-color;
	font-size: $chip-font-size;
	line-height: 1;

	&-content,
	&-delete
	{
		@extend .btn-reset;
	}

	&-content
	{
		@include text-ellipsis($chip-content-width);
		padding: $chip-padding-y 0 $chip-padding-y $chip-padding-x;
		border-top-left-radius: $chip-border-radius;
		border-bottom-left-radius: $chip-border-radius;
		color: $chip-color;

		&:hover, &:hover + .chip-delete
		{
			background: $chip-hover-color;
		}
	}

	&-delete
	{
		@include text-semi-bold;
		border-top-right-radius: $chip-border-radius;
		border-bottom-right-radius: $chip-border-radius;
		padding: $chip-padding-y * .5;
		color: $chip-btn-color;

		.fal
		{
			@include circle(20px);
			@include flex-center;
		}

		&:hover
		{
			.fal
			{
				background: $chip-hover-color;
				color: $chip-color;
			}
		}
	}

	&-disabled
	{
		padding: $chip-padding-y;

		&:hover, &:hover + .chip-delete
		{
			background: transparent;
		}
	}

	@each $type, $colors in $chip-color-type 
	{
		&-#{$type}
		{
			border-color: list.nth($colors, 3);
			background: list.nth($colors, 2);

			.chip-content, .chip-delete
			{
				@include text-bold;
				color: list.nth($colors, 1);
			}

			.chip-content:hover,
			.chip-content:hover + .chip-delete
			{
				background: color.adjust(list.nth($colors, 2), $lightness: -5%);
				color: color.adjust(list.nth($colors, 1), $lightness: -5%);
			}

			.chip-delete
			{
				&:hover
				{
					.fal 
					{
						background: transparent;					
					}
				}
			}
		}
	}	
}