/* ========================================================================== */
/* CAROUSEL */
/* Carousel formatting classes */
/* ========================================================================== */


.carousel 
{
	outline: none;

	&-control 
	{
		&-prev, &-next
		{
			@include pos-center-y;
			bottom: auto;
			width: auto;
			padding: 5px;
			border-radius: 50%;
			background: rgba($black, .5);
			cursor: pointer;

			&-icon
			{
				width: 15px;
				height: 15px;
			}
		}

		&-prev
		{
			left: -30px;
		}

		&-next
		{
			right: -30px;
		}
	}

	&-indicators 
	{
		bottom: -30px;

		li 
		{
			background-color: rgba($black, .2);

			&.active 
			{
				background-color: rgba($black, .5);
			}
		}
	}

	&-item
	{
		text-align: center;
	}

	img 
	{
		max-height: 60vh;
	}

	iframe
	{
		height: 60vh;
	}

	img, iframe
	{
		width: 100%;
	}

	.document-error {
		max-width: 500px;
		margin: $spacing-lg auto;
	}
}

.document-download {
	text-align: center;

	&-name
	{
		margin-bottom: $spacing;
	}
}
