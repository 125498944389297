
.graph {

    &-title:hover {
        text-decoration: none;
    }

    &-block {
        padding: 20px;
        padding-top: 1rem;
        min-height: 200px;
        height: 100%;
    }
    
    &-total, &-zero-data  {
        text-align: center;
        margin: 6.5rem 0;
    }

	&-row-zero-data {
		margin-top: 2.6rem;
	}

}

.graph .chart-legend {
    color: $charts-legend-color;
    .legend-label-text,
    .legend-title-text {
        color: $charts-legend-color;
        &:hover {
            text-decoration: underline;
        }
    }
    .legend-label .active {
        .legend-label-text,
        .legend-title-text {
            color: lighten($charts-legend-color, 0.5);
        }    
    }
}

svg.world-map path {
    fill: grey;
}

circle {
    outline: none;
    cursor: pointer;
}

ngx-masonry {
    margin-right: -10px;
}

.masonry-item {
    transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
    margin: 0 0 10px 0;
    padding: 0;
}

@media (min-width: 1440px) {
    .masonry-item {
        &.col-xl-12 { width: 100% }
        &.col-xl-11 { width: 90%  }
        &.col-xl-10 { width: 82%  }
        &.col-xl-9  { width: 74%  }
        &.col-xl-8  { width: 66.6%  }
        &.col-xl-7  { width: 58%  }
        &.col-xl-6  { width: 49.9%  }
        &.col-xl-5  { width: 40%  }
        &.col-xl-4  { width: 33.2%  }
        &.col-xl-3  { width: 24%  }
    }
}

.home-charts-turnover-world-map,
.home-charts-turnover-line {
    min-height: 685px;
}

.home-charts-dso,
.top-outstanding-treemap,
.home-charts-ageing-balance,
.home-charts-average-overdue,
.home-charts-estimated-cashing,
.home-chart-pie-debtor-scorings,
.top-debtors-with-claims-treemap,
.home-charts-outstanding-by-dunning-level {
    min-height: 400px;
}
