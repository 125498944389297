// Force charts to fill container height
.ngx-charts-outer::after {
    display: block;
    clear: both;
    content: "";
}

.treemap-label {
    display: block;
    text-align: center;
    margin: 5px 0;
}

.chart-legend {

  .legend-title {
      overflow: visible;
  }

  .legend-labels {
      .legend-label {
          margin-left: 14px;
      }
  }
}

.chart-legend-alt {
    margin-top: 20px;

    .legend-labels {
        background: transparent !important;
        
        .legend-label {
          background: #CEE5FF;
          border-radius: 5px;
          padding: 7px;
        }

        .legend-label-text,
        .legend-label-value {
            display: block;
            color: #000;
        }

        .legend-label-text {
            color: rgba(0, 0, 0, 0.7);
            font-weight: bold;
        }

        .legend-label-value {
            color: #616161;
        }
    
        .legend-label-text:hover,
        .legend-title-text:hover {
            text-decoration: none;
        }    
    }
}

.not-clickable .ngx-charts {
  .circle, circle,
  .arc,
  .cell,
  .bar-clickable {
    // Bug 26083: Acc [Mo/Conso-Graphiques] : transformation du curseur de la souris en main au survol de certains graphiques
    cursor: default !important;
  }
}

.ngx-charts {
    float: left;
    overflow: visible;
  
    .circle,
    .arc,
    .cell,
    .bar-clickable {
      cursor: pointer;
    }
  
    .bar,
    .cell,
    .arc,
    .card {
      &.active,
      &:hover {
        opacity: 0.8;
        transition: opacity 100ms ease-in-out;
      }
  
      &:focus {
        outline: none;
      }
      &.hidden {
        display: none;
      }
    }
  
    g {
      &:focus {
        outline: none;
      }
    }
  
    .line-series,
    .line-series-range,
    .polar-series-path,
    .polar-series-area,
    .area-series {
      &.inactive {
        transition: opacity 100ms ease-in-out;
        opacity: 0.2;
      }
    }
  
    .line-highlight {
      display: none;
  
      &.active {
        display: block;
      }
    }
  
    .area {
      opacity: 0.6;
    }
  
    .circle {
      &:hover {
        cursor: pointer;
      }
    }
  
    .label {
      font-size: 12px;
      font-weight: normal;
    }
  
    .tooltip-anchor {
      fill: rgb(0, 0, 0);
    }
  
    .gridline-path {
      stroke: #ddd;
      stroke-width: 1;
      fill: none;
    }
  
    .refline-path {
      stroke: #a8b2c7;
      stroke-width: 1;
      stroke-dasharray: 5;
      stroke-dashoffset: 5;
    }
  
    .refline-label {
      font-size: 9px;
    }
  
    .reference-area {
      fill-opacity: 0.05;
      fill: #000;
    }
  
    .tick {
      fill: $charts-tick-color;
    }

    .gridline-path-dotted {
      stroke: #ddd;
      stroke-width: 1;
      fill: none;
      stroke-dasharray: 1, 20;
      stroke-dashoffset: 3;
    }
  
    .grid-panel {
      rect {
        fill: none;
      }
  
      &.odd {
        rect {
          fill: rgba(0, 0, 0, 0.05);
        }
      }
    }
}

.with-x-ticks-rotated-neg-45 g[ngx-charts-x-axis-ticks] .tick text[text-anchor='middle'] {
  transform: translate(-15px, 15px) rotate(-45deg) !important;
}