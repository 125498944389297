/* ========================================================================== */
/* DROPDOWNS */
/* Dropdown component styles */
/* ========================================================================== */

/* Bootstrap overrides
 * ========================================================================== */
.dropdown,
.dropup
{
	display: inline-block;

	&.dropdown-full-width
	{
		display: block;
	}

	&-item {
		background: $dropdown-item-bg;
	}
}

.dropdown-menu
{
	box-shadow: $dropdown-box-shadow;
}

.dropdown-full-width
{
	.dropdown-toggle
	{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;

		&::after {
			position: absolute;
			right: 5px;
		}
	}

	.dropdown-menu
	{
		min-width: 100%;
	}
}

.dropdown-toggle
{
	cursor: pointer;

	&.disabled,
	&[disabled]
	{
		opacity: .6;
		cursor: not-allowed;
	}

	&:not(.no-carret)
	{
		&:after
		{
			@extend %fa-icon;
			@extend .fal;
			content: fa-content($fa-var-angle-down);
		}
		
		&:not(.dropdown-toggle-split):after
		{
			margin-left: $spacing-xs;
		}
	}
}


/* Add
 * ========================================================================== */
.custom-dropdown 
{
	padding: $custom-dropdown-padding;
	border: $custom-dropdown-border;
	border-radius: $border-radius;

	&-validation
	{
		@include text-italic;
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		width: $custom-dropdown-width;
		background: $input-alt-bg;
		color: $input-alt-color;
	}

	&-status
	{
		text-transform: uppercase;
		background: $orange;
		border-color: $orange;
		color: $white;
	
		&.success 
		{
			background: $success;
			border-color: $success;
		}

		&.refused 
		{
			background: $danger;
			border-color: $danger;
		}
	}
	
	.validation-success,
	.validation-refused 
	{
		font-style: normal;

		&:before 
		{
			@extend %fa-icon;
			@extend .fal;
			margin-right: 5px;
		}
	}
	
	.validation-success 
	{
		color: $success;
		
		&:before 
		{
			content: fa-content($fa-var-check);
		}
	}
	
	.validation-refused 
	{
		color: $danger;
		
		&:before 
		{
			content: fa-content($fa-var-times);
		}
	}
	
	&:focus 
	{
		outline: none;
	}

	i 
	{
		margin-left: $spacing-xs;
	}
}


/* Multi select
 * ========================================================================== */
.multi-select
{
	&-control
	{
	}

	&-btn
	{
	}

	&-list
	{
		max-height: 300px;
		overflow: auto;

		.form-check
		{
			cursor: pointer;
			margin: 0;
			padding: 5px $spacing-xs;
	
			&-label
			{
				display: block;
			}

			a
			{
				margin-left: 5px;
				visibility: hidden;
			}
	
			&:hover
			{
				background: $dropdown-link-hover-bg;
				color: $dropdown-link-hover-color;

				a
				{
					visibility: visible;
				}
			}
		}
	}

	&-clear
	{
		margin-top: 5px;
	}
	
}