/* ========================================================================== */
/* USER AVATAR VARS */
/* User avatar vars */
/* ========================================================================== */

$user-avatar-sizes:		(
							"xs": (15px, 9px),
							"sm": (25px, 10px),
							"md": (40px, 18px),
							"lg": (50px, 20px)
						) !default;

$user-avatar-colors:	(
							"gray": ($gray-100, $gray-600),
							"blue": ($blue, $white),
							"green": ($green, $white),
							"pink": ($pink, $white)
						) !default;

$user-avatar-name-color:	$gray-450 !default;
