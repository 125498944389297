/* ========================================================================== */
/* FORMS */
/* Forms component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */

/* Form labels */
.form-label
{
	color: $form-label-color;
	font-size: $form-label-font-size;
	text-transform: uppercase;

	&-required:after {
		content: ' *';
	}

	&-dark
	{
		color: $form-label-color-dark;
	}

	&-full
	{
		display: block;
	}

	.form-group & 
	{
		margin-bottom: $spacing-xs;
	}

	.form-inline &
	{
		margin-right: $spacing-xs;
		margin-bottom: 0;

	}
}

/* Form group */
.form-group
{
	&-hidden
	{
		visibility: hidden;
	}

	&-disabled
	{
		opacity: .5;
	}

	&-right
	{
		text-align: right;
	}

	&.has-label-placeholder
	{
		padding-top: $form-label-font-size-px * $line-height-base + $spacing-xs;
	}

	&.has-half-label-placeholder
	{
		padding-top: $spacing;
	}

	.field-explanation {
		color: $gray-450;
    	font-size: .85714rem;
	}
}

/* Form control states */
.form-control
{
	&.is-invalid
	{
		background: $input-invalid-bg;
	}

	&.is-valid
	{
		background: $input-valid-bg;
	}
	
	&-alt
	{
		&:not(.is-invalid):not(.is-valid)
		{
			background: $input-alt-bg;
			border-color: $input-alt-border-color;
			color: $input-alt-color;
		
			&:focus
			{
				background: $input-alt-focus-bg;
				border-color: $input-alt-focus-border-color;
				color: $input-alt-focus-color;
			}

			&[disabled] 
			{
				background: $input-disabled-bg;
				opacity: .6; // to match others
			}
		}

		&.form-control-without-value:not(.is-invalid):not(.is-valid) {
			color: $custom-control-without-value-color;

			option {
				color: $input-alt-color;
			}
		}
	}

	&-daterangepicker
	{
		width: $input-datepicker-w;
	}
	
	&-date-expression
	{
		width: $input-date-expression-w;
	}

	&:not(input):not(select):not(textarea)
	{
		height: auto;
	}

	// Bug 20852: Acc [Mo-Relance-Scénario] Firefox : impossible d'augmenter ou diminuer le nombre de jours avec les "flèches" : clic impossible
	&[type=number] {
		padding: $input-padding-y 2px $input-padding-y $input-padding-x;
	}
}

/* Input group */
.input-group
{
	$context: &;

	&-between
	{
		.form-control,
		#{$context}-prepend
		{
			margin: 0;
		}

		.input-group-text {
			border: none;
		}
	}

	.btn i
	{
		display: block;
	}
}

select.form-control
{
	&[disabled],
	&[readonly]
	{
		appearance: none;
	}
}

.input-icon-group
{
	position: relative;

	input 
	{
		padding-right: 35px;
	}

	i
	{
		@include position-absolute($spacing-xs, $spacing-xs);
		color: $gray-450;
		font-size: $font-size-lg;
	}
}


select.form-control
{
	@include padding-y(0);
	height: $custom-select-height;
}

/* Form texts */
.form-text,
.invalid-feedback,
.valid-feedback
{
	@include text-italic;
	font-size: $font-size-sm;
}

.form-feedbacks
{
	.invalid-feedback,
	.valid-feedback { display: block; }
}

.form-text
{
	a
	{
		color: $gray-700;
	}
}

.form-field-mapping-symbol
{
	justify-content: center;
	text-align: center;
	align-self: center;
	font-size: $font-size-xl;
}

/* Customizations
 * ========================================================================== */

/* Form actions */
.form-actions
{
	margin: 30px 0 0;

	.page-header & { margin-top: 0; }
	
	&-inline
	{
		text-align: right;

		> button,
		> a
		{
			+ button,
			+ a,
			+ .dropdown,
			+ .dropup { margin-left: $spacing-xs; }
		}
	}

	&-spaced
	{
		@include flex-spacebetween(center);

		button[type="submit"]
		{
			margin-left: auto;
		}
	}
}


/* Custom checkbox / radio */
.form-check,
.form-radio
{
	&-input
	{
		&:checked + .form-check-label:before
		{
			font-size: $checkbox-radio-font-size;
			color: $checkbox-radio-active-color;
		}

		&[disabled] + label:before {
			background: $checkbox-radio-disabled-bg;
		}
	}

	&-label
	{
		@include text-bold;
		position: relative;
		padding-left: $checkbox-radio-label-offset;

		&:before
		{
			@include rectangle($checkbox-radio-size);
			@include border(null, $checkbox-radio-color, 1px);
			@include position-absolute(3px, null, null, 0);
			border-radius: $checkbox-radius;
			background: $checkbox-radio-bg;
			color: $checkbox-radio-color;
		}

		a
		{
			font-size: $font-size-sm;
		}
	}

	&-revert
	{
		text-align: right;
		
		.form-check-label,
		.form-radio-label
		{
			padding-right: $checkbox-radio-label-offset;
			padding-left: 0;

			&:before
			{
				right: 0;
				left: auto;
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}

	&-inline + &-inline
	{
		margin-left: $spacing;
	}
}

.form-check
{
	&-input
	{
		&:checked + .form-check-label:before
		{
			content: fa-content($fa-var-check);
		}
	}

	&-label
	{

		&:before
		{
			@extend %fa-icon;
			@extend .fal;
			border-radius: $checkbox-radius;
		}
	}
}

/* Custom switch */

.form-switch-isPayingCenter {
	margin-top: -4px;
	
	popover-component {
		position: absolute;
		margin-left: -50px;
		margin-top: 2px;
	}

	btn-switch {
		span {
			margin-left: 26px !important;
		}
	}
}

/* Custom radio */
.form-radio
{
	&-input
	{
		&:checked + .form-radio-label:before
		{
			box-shadow: 0 0 0 4px $checkbox-radio-bg inset;
			background: $checkbox-radio-active-color;
		}
	}

	&-label
	{
		&:before
		{
			border-radius: $radio-radius;
		}
	}
}

/* Form sections
 * ========================================================================== */

 /* Generic form block */
.form-content
{
	border-radius: $border-radius;
	background: $form-content-bg;
	border: $form-content-border;
	box-shadow: $box-shadow;
	padding: $form-content-padding;


	&-title
	{
		border-bottom: $form-content-border;
		padding: 0 0 $spacing;
	}
}

/* Files
 * ========================================================================== */
.file-dragover
{
	opacity: $file-upload-dragover-opacity;
	outline: $file-upload-dragover-outline;
}

