/* ========================================================================== */
/* KYC PAGES */
/* ========================================================================== */

/* kyc pages with table-flex inside
* ========================================================================== */
.kyc-control-fiben,
.kyc-control-ids
{
	margin-bottom: -40px;
}


/* kyc lcbft
* ========================================================================== */
.kyc-control-lcbft
{
	&-item 
	{
		@include generic-block-style($kyc-lcbft-item-margin, $kyc-lcbft-item-padding);

		&-row
		{
			display: flex;

			&:first-child 
			{
				margin-bottom: 30px;
			}
		}

		&-col 
		{
			flex: 1;
		}
	}

	p 
	{
		padding-bottom: $spacing-xs;
	}
}

/* kyc create seller
* ========================================================================== */
.kyc-control-create-seller
{
	.row-description
	{
		margin: $spacing-xs 0;
		padding-left: $kyc-seller-padding;
		color: $kyc-seller-color;
		font-size: $kyc-seller-font;
	}
}

.register-lynx-block
{
	padding-bottom: $spacing;
}


/* kyc sellers alerts
* ========================================================================== */
.kyc-control-seller-alerts
{
	.alert-danger
	{
		@include text-bold;
		border-color: transparent;
		background: $danger-light;
		color: $gray-800;

		&:after
		{
			@extend %fa-icon;
			@extend .fal;
			@include pos-center-y;
			@include text-semi-bold;
			content: fa-content($fa-var-angle-right);
			right: $spacing;
			margin-left: $spacing-xs;
		}
	}
}

.icon-warning 
{
	@include flex-center(null, inline-flex);
	@include circle($kyc-alert-icon-size);
	margin-right: $spacing-xs;
	background: $red;
	color: $white;
	font-size: $font-size-xl;
	vertical-align: text-bottom;

	&:after 
	{
		content: '!';
	}
}
