/* ========================================================================== */
/* FILTERS */
/* Filtered list view (using filter builder, table and pager) */
/* ========================================================================== */

/* Structure
 * ========================================================================== */
.chips-wrapper
{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

/* Active filters list
 * ========================================================================== */
.active-filters
{
	display: flex;
	align-items: flex-start;

	&-wrap
	{
		@include flex-spacebetween(center);
		padding: $filters-padding;

		.filters-selection &
		{
			padding-left: 0;
			padding-right: 0;
		}
	}

	&-legend
	{
		margin: 0;
		
		@include media-breakpoint-up(lg) 
		{
			display: flex;
		}

		.legend-item
		{
			color: $gray-700;
			display: flex;
			align-items: center;
	
			&:before
			{
				@include bullet;
				flex-shrink: 0;
				flex-grow: 0;
			}
	
			@each $type, $colors in $chip-color-type 
			{
				&-#{$type}:before
				{
					background: nth($colors, 1);
				}
			}

			@include media-breakpoint-up(lg) 
			{
				&:not(:last-child)
				{
					margin-right: $spacing;
				}
			}
		}
	}

	&-detail
	{
		.btn
		{
			margin-left: $spacing;
		}
	}

	.form-label
	{
		flex-shrink: 0;
		padding-top: $active-filters-label-padding-top;
		color: $active-filters-label-color;
		line-height: 1;
	}
	
	.chip
	{
		margin-left: $chip-spacing;
	}

	.btn-switch
	{
		text-transform: none;
	}
}

.btn-filters-action
{
	@extend .btn-reset;
	@include circle($filters-action-button-size);
	position: relative;
	transition: $transition-all;
	
	.fal
	{
		@include pos-center;
		@include text-semi-bold;
		font-size: $filters-action-button-font-size;
	}

	&.btn-add
	{
		color: $filters-action-button-add-color;
		background: $filters-action-button-add-bg;
	}

	&.btn-remove
	{
		color: $filters-action-button-remove-color;
		background: $filters-action-button-remove-bg;
	}

	&[disabled]
	{
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
	}
}

.btn-add-filters, .btn-add-filters-upper
{
	@extend .btn-filters-action;
	margin-left: $filter-spacing;
	color: $filters-action-button-create-color;
	background: $filters-action-button-create-bg;

	&-wrapper
	{
		@include flex-center;
		position: relative;
		height: $filters-action-button-height;
	}

	&:after
	{
		@include losange($filters-action-button-triangle-size, $filters-builder-bg);
		@include border-top($filters-builder-border-color);
		@include border-left($filters-builder-border-color);
		z-index: 2;
		opacity: 0;
		transition: $transition-all;
		pointer-events: none;
		cursor: default;
	}
	
	.filters-builder-opened &
	{
		background: $filters-action-button-close-bg;
		color: $filters-action-button-close-color;

		.fal
		{
			&:before { content: fa-content($fa-var-times); }
		}

		&:after
		{
			transition: $transition-all;
			opacity: 1;
		}
	}
}

.btn-add-filters {
	&:after
	{
		@include position-absolute($filters-action-button-triangle-top, auto, auto, $filters-action-button-triangle-left);
	}
}
.btn-add-filters-upper {
	&:after
	{
		@include position-absolute($filters-action-button-triangle-top-upper, auto, auto, $filters-action-button-triangle-left);
	}
}

/* Filters builder form
 * ========================================================================== */
.filters-builder
{
	border: 1px solid $filters-builder-border-color;
	background: $filters-builder-bg;
	flex-direction: column;
	align-items: normal;
	padding: $filters-padding;
	visibility: hidden;
	transition: $transition-all;

	.filters-builder-opened &
	{
		visibility: visible;
	}

	&-wrapper
	{
		@include toggle-animation('.filters-builder-opened &', $filters-builder-max-height, 0, $filters-builder-transition, $filters-builder-transition);
	}
	
	.filter-form,
	&-form
	{
		.ng2-tag-input,
		.ng2-tag-input.ng2-tag-input--focused
		{
			border-bottom: none;
		}

		button + button
		{
			margin-left: $filter-spacing;
		}
	}
	
	&-label
	{
		@extend .form-label;
		margin: 0 0 $filter-spacing;
	}

	&-table
	{
		table-layout: fixed;
		width: 100%;
		margin: 0 0 $spacing;

		th
		{
			@extend .form-label;
		}

		th, td
		{
			padding: 5px $filter-spacing;
			vertical-align: top;

			&:first-child
			{
				padding-left: 0;
			}

			&:last-child
			{
				padding-right: 0;
			}
		}

		&-control
		{
			width: $filters-builder-row-control-w;
		}

		&-actions
		{
			width: $filters-builder-row-action-w;
			text-align: right;

			button
			{
				display: inline-flex;
			}
		}
	}

	&-actions
	{
		text-align: right;

		.btn-a
		{
			color: $btn-link-color;
		}
	}

	.input-group
	{
		.form-control
		{
			margin: 0;
		}

		&-prepend
		{
			background: $filters-builder-bg;
		}
	}

	.ng2-tag-input
	{
		max-width: 500px;
		margin-right: $filter-spacing;
	}

	.block-loader .loader 
	{
		background: rgba($filters-builder-bg, $page-loader-overlay-opacity);
	}
}

/* Saved filters
 * ========================================================================== */
.saved-filters
{
	margin: 0 0 $spacing;
	align-items: center;
}