/* ========================================================================== */
/* TOP BAR */
/* Top bar's elements */
/* ========================================================================== */

/* Structure
 * ========================================================================== */
#top-bar
{
	@include flex-spacebetween(center);
	z-index: 3;
	height: $top-bar-h;
	padding: $top-bar-padding;
	background: $top-bar-bg;

	.tenant-title h1 {
		margin-bottom: 0;
	}
}


/* Search form
 * ========================================================================== */
#top-bar {
	.ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
		max-height: 340px;
	}

	.ng-select-container {
		width: $search-form-w;

		.ng-clear-wrapper, .ng-arrow-wrapper {
			display: none !important;
		}
	}
}

.search-form
{
	position: relative;

	.icon-search
	{
		@include pos-center-y;
		left: -1.3em;
		z-index: 3;
		color: $gray-600;
		pointer-events: none;
	}

	.input-group
	{
		transition: $transition-all;

		.form-control 
		{
			margin-right: $spacing-xs;
			border-radius: $input-border-radius;
			max-width: 200px;
		}

		margin-right: $spacing-xs;
		border-radius: $input-border-radius;
	}

	.form-control
	{
		height: auto;
		padding-left: $spacing-md;
	}

}


/* User actions
 * ========================================================================== */
.user-actions
{
	display: flex;
	align-items: center;
}

.btn-notifications
{
	@extend .btn-reset;
	@include circle($top-bar-notif-circle-size);
    position: relative;
    font-size: $top-bar-notification-size;
	color: $gray-600;

	span.badge
	{
		top: $spacing-xs;
		right: $spacing-xs;
		font-size: 0.65rem;
	}

	i 
	{
		@include pos-center;
	}
	
	&:after 
	{
		@include position-absolute($top-bar-notif-bullet-y, $top-bar-notif-bullet-x);
		@include circle($top-bar-notif-bullet-size);
		content: '';
		display: none;
		background: $top-bar-notification-bullet-color;

		.has-unread-notifications & {
			display: block;
		}
	}

	.notif-opened & 
	{
		background: $top-bar-notification-bg-open;

		&:before 
		{
			@include position(absolute, null, null, $top-bar-notif-triangle-y, $top-bar-notif-triangle-x);
			@include losange(7px, $white);
			@include border-top($gray-300);
			@include border-left($gray-300);
		}
	}
}

.btn-help {
	@extend .btn-reset;
	@include circle($top-bar-notif-circle-size);
	position: relative;
	font-size: $top-bar-notification-size;
	color: $gray-600;

	i {
		@include pos-center;
	}
}

.user-languages,
.user-account
{
	margin-left: $spacing-md;
}
