.cke_panel_block {
    display: table;
    width: 100%;
}

.cke_editable {
    line-height: normal;
}

.readonly-editor .cke_widget_drag_handler_container {
    display: none !important;
}

body.cke_editable.maximized,
body .cke_inner.cke_maximized .cke_contents {
    width: 21cm;
    margin: 1em auto;
    padding: 1.8cm 2cm;
    min-height: 29cm;
    background: white;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

.cke_maximized,
html.maximized {
    cursor: text;
    background: #A4A4A4
}

body .cke_editable[contenteditable="false"] .cke_placeholder {
    background-color: transparent;
}

body .read-mode-form .cke_button:not(.cke_button__maximize) {
    opacity: .3;
}

body .cke_widget_wrapper:hover > .cke_widget_element {
    outline: none;
    border: none;
}

body .cke_editable[contenteditable="false"] .cke_widget_drag_handler,
body .cke_editable[contenteditable="false"] .cke_widget_drag_handler_container {
    display: none !important;
}

body .cke_combopanel
{
	width: 300px;
}

body .cke_toolbox a.cke_button,
body .cke_toolbox a.cke_button_disabled:hover,
body .cke_toolbox a.cke_button_disabled:focus,
body .cke_toolbox a.cke_button_disabled:active,
body .cke_toolbox a.cke_combo_button,
body .cke_toolbox a.cke_combo_button_disabled:hover,
body .cke_toolbox a.cke_combo_button_disabled:focus,
body .cke_toolbox a.cke_combo_button_disabled:active
body .cke_toolbox a.cke_button_expandable,
body .cke_toolbox a.cke_button_expandable_disabled:hover,
body .cke_toolbox a.cke_button_expandable_disabled:focus,
body .cke_toolbox a.cke_button_expandable_disabled:active,
body a.cke_button_expandable.cke_button_off:hover,
body a.cke_button_expandable.cke_button_off:focus,
body a.cke_button_expandable.cke_button_off:active
{ 
    border: 1px solid transparent;
    padding: 4px 6px;
}
body .cke_toolbox .cke_combo a.cke_combo_button,
body .cke_toolbox .cke_combo a.cke_combo_button_disabled:hover,
body .cke_toolbox .cke_combo a.cke_combo_button_disabled:focus,
body .cke_toolbox .cke_combo a.cke_combo_button_disabled:active
{ 
    margin: 0;
    padding: 0;
}

body .cke_toolbox a.cke_button_on {
    border: 1px #bcbcbc solid;
}
