/* ========================================================================== */
/* DUNNING PAGES */
/* Dunning pages specific styles  */
/* ========================================================================== */

/* Super-Debtor dunning timeline
 * ========================================================================== */
.dunning-timeline
{
	$context: &;

	&-actions
	{
		display: flex;
		justify-content: flex-end;
		align-items: center;

		> *
		{
			margin-left: $spacing;
		}

		multi-select
		{
			display: inline-block;
			margin-left: 5px;
			width: 250px;
		}
	}

	// Common elements / Structure
	&-item
	{
		padding: $super-debtor-dunning-timeline-item-spacing 0 0;

		&-first
		{
			padding-top: 0;
		}

		&s
		{
			background: linear-gradient(
				to right,
				transparent $super-debtor-dunning-timeline-line-start,
				$super-debtor-dunning-timeline-line-color $super-debtor-dunning-timeline-line-start,
				$super-debtor-dunning-timeline-line-color $super-debtor-dunning-timeline-line-stop,
				transparent $super-debtor-dunning-timeline-line-stop
			);
		}

		&-header
		{
			position: relative;
			padding: $super-debtor-dunning-timeline-item-header-padding;

			&:before
			{
				@include position-absolute(0, null, null, 18px);
				@include circle($super-debtor-dunning-timeline-item-marker-size);
				content: '';
				display: block;
				background: $super-debtor-dunning-timeline-item-marker-bg;
				border: $super-debtor-dunning-timeline-item-marker-border;
			}
		}

		&-status
		{
			display: block;
		    position: relative;
			top: -3px;
			font-size: $super-debtor-dunning-timeline-item-status-font-size;
			line-height: $headings-line-height;
		}

		&-infos
		{
			font-size: $super-debtor-dunning-timeline-item-infos-font-size;
		}

		&-content
		{
			display: flex;
			box-shadow: $content-block-shadow;
			border-radius: $border-radius;
			border: $super-debtor-dunning-timeline-item-content-border;
			background: $super-debtor-dunning-timeline-item-content-bg;
			position: relative;
		}

		&-meta,
		&-detail
		{
			padding: $super-debtor-dunning-timeline-item-content-padding;

			.infos-list li {
				border: none;
			}
		}

		&-meta
		{
			flex-shrink: 0;
			flex-grow: 0;
			width: $super-debtor-dunning-timeline-item-meta-width;
			padding: 0;
			position: relative;

			// Overlapping border illusion
			border-radius: $border-radius 0 0 $border-radius;
			border: $super-debtor-dunning-timeline-item-content-border;
			margin: -$super-debtor-dunning-timeline-item-content-border-w;

			&-body {
				padding: $spacing-xs $spacing*2 $spacing-xs $spacing-xs;
			}

			&-footer {
				text-align: center;
				background: #eff6fd;
				font-size: 90%;
				color: $gray-700;

				&-item {
					border-top: $super-debtor-dunning-timeline-item-content-border;
				}
			}
		}

		&-type
		{
			line-height: $headings-line-height;
			padding: $spacing-xs 0 0;
		}

		&-text
		{
			@include flex-spacebetween(flex-start);
			margin-top: 2px;

			strong
			{
				display: block;
				min-height: $super-debtor-dunning-timeline-item-type-h;
			}
		}

		&-icon
		{
			font-size: $font-size-lg;
			position: relative;
			top: -2px;
		}

		&-level
		{
			margin-left: 5px;
		}

		&-operator
		{
			margin-top: 5px;

			.label
			{
				@extend %infos-list-label;
			}

			.warning {
				color: red;
			}
		}

		&-detail
		{
			flex: 1;
		}

		// Collapsed state
		&-collapse
		{
			&d
			{
				&-toggle
				{
					@include position-absolute(5px, 5px);
					z-index: 2;
					color: $body-color;

					i { font-size: $font-size-lg; }
				}

				& &-hidden
				{
					display: none;
				}
			}
		}

		&-system-icon
		{
			@include position-absolute($spacing-xs, $spacing-xs);
		}
	}

	// Action type item
	&-action
	{
		#{$context}-item
		{
			&-detail
			{
				.infos-list
				{
					margin: 0;
				}
			}
		}

		// Action to do
		&-todo
		{
			#{$context}-item
			{
				&-header
				{
					&:before { border-color: $super-debtor-dunning-timeline-action-todo-color; }
				}

				&-status
				{
					color: $super-debtor-dunning-timeline-action-todo-color;
				}

				&-meta
				{
					background: $super-debtor-dunning-timeline-action-todo-meta-bg;
					border-color: $super-debtor-dunning-timeline-action-todo-meta-border;
				}
			}
		}

		// Action done
		&-done
		{
			#{$context}-item
			{
				&-header
				{
					&:before { border-color: $super-debtor-dunning-timeline-action-done-color; }
				}

				&-status
				{
					color: $super-debtor-dunning-timeline-action-done-color;
				}

				&-meta
				{
					background: $super-debtor-dunning-timeline-action-done-meta-bg;
				}
			}
		}

		// With preview
		&-has-preview
		{
			#{$context}-item-meta
			{
				cursor: pointer;

				&:hover
				{
					background: $super-debtor-dunning-timeline-action-done-meta-hover-bg;
				}
			}
		}
	}

	// Event type item
	&-event
	{
		#{$context}-item
		{
			&-content
			{
				border: $super-debtor-dunning-timeline-item-content-border;
				background: $super-debtor-dunning-timeline-event-content-bg;
				padding: $super-debtor-dunning-timeline-item-content-padding;
				justify-content: center;
			}
		}
	}

	// More
	&-btn-more
	{
		margin: $spacing-md 0 0;
	}
}



/* Dunning group edit 
 * ========================================================================== */
.dunning-group-edit-page
{
    form
    {
        .active-filters
        {
            padding-top: 0;
            padding-left: 0;        
        }
    }
}


/* Dunning action modal
 * ========================================================================== */
.dunning-action
{
	&-modal
	{
		&-actions
		{
			display: flex;
			justify-content: space-between;
		}

		.table th, .table td
		{
			padding: $spacing-xs;
		}

		.tab-content
		{
			overflow: auto;
			overflow-x: hidden;
			padding-right: $spacing;
    		margin-right: -$spacing;
		}

		.tab-content,
		.tab-content-fitted
		{
			$modal-content-padding: $spacing-md;
			$modal-content-tabs-btn-h: 190px;

			// Backdrop vertical margin - Content vertical padding - Border vertical size - (Tabs + buttons) height
			height: calc(
				100vh -
				#{$modal-dialog-margin-y-sm-up} * 2 -
				#{$modal-content-padding} * 2 -
				#{$modal-content-border-width} *2 -
				#{$modal-content-tabs-btn-h});
		}
	}

	&-accounting
	{
		margin: $spacing 0;
	}
}

