/* ========================================================================== */
/* COMMENTS */
/* Comment component styles */
/* ========================================================================== */

/* Form comments component */
 .form-comment
 {
	&-label
	{
		padding-bottom: $spacing-xs;
		color: $comment-label-color;
		font-size: $font-size-sm;
	}

	&-list
	{
		margin-top: $spacing;
	}

	&-inner
	{
		padding-top: $spacing-lg;
	}

	&-input
	{
		display: flex;

		textarea
		{
			resize: vertical;

			&+ .btn
			{
				align-self: flex-start;
				margin-left: $spacing-xs;
			}
		}
	}
 }

 /* Comment component */
 .comment-item
 {
	&-head
	{
		margin-bottom: $spacing-xs;
	}

	.card-item-body {
		word-break: break-all;
		white-space: pre-wrap; // to preserve newlines
	}
 }
 