@use "sass:color";

/* ========================================================================== */
/* ADMIN pages styles */
/* ========================================================================== */

/* Workflow Instances list
 * ========================================================================== */
admin-tools-workflow-scheduling-instances-list-table
{
	.col-name {
		width: 60%;
	}
}

/* Workflow Scheduling graph
 * ========================================================================== */
.workflow-scheduling-graph
{
	margin: $spacing-md 0 0;

	&-legend
	{
		display: flex;
		justify-content: flex-end;
		margin: 0 0 $spacing-xs;

		.status-info
		{
			margin: 0 0 0 $spacing-xs;
		}
	}

	svg
	{
		@include border;
		@include rectangle(100%, 1000px);

		.node
		{
			&.has-workflow-children {
				stroke-width: 2px;
				stroke-dasharray: 4px;
			}

			rect
			{
				stroke: $black;
				fill: $gray-100;
				max-width: 200px;
			}

			@each $type, $color in $status-colors
			{
				&.#{$type} rect
				{
					fill: color.adjust($color, $lightness: 30%);
					stroke: $color;
				}
			}

			.pending {
				animation: animate-border 10s infinite linear;
			}

			@keyframes animate-border {
				to {
			   		stroke-dashoffset: 1000;
				}
		   	}
		}

		.edgePath path
		{
			stroke: $black;
			fill: $black;
			stroke-width: 1px;
		}
	}

	&-tooltip
	{
		border-radius: $tooltip-border-radius;
		background: rgba($tooltip-bg, $tooltip-opacity);
		color: $tooltip-color;
		font-size: $font-size-sm;
		padding: $tooltip-padding-y $tooltip-padding-x;
		max-width: $tooltip-max-width;
		position: absolute;
		visibility: hidden;
		z-index: $zindex-tooltip;
		word-break: break-all;

		h4
		{
			color: $tooltip-color;
		}
	}
}

/* Workflow Scheduling graph panel
 * ========================================================================== */
admin-tools-workflow-scheduling-graph-panel {

	.content-control-block {
		padding: 30px 0;
	}
}

.workflow-scheduling-graph text::selection,
.workflow-scheduling-graph tspan::selection {
	background: none;
}

/* Extradata config
 * ========================================================================== */
.admin-tools-extradata-config-page, 
.admin-tools-claim-types-config-page
{
	.table-light {
		thead .col-medium {
			width: 20%;
		}

		tbody td {
			vertical-align: top;
			border-bottom: none;
			border-right: none;
			border-left: none;

			&.col-id {
				padding: 1.7em 1.8em;
			}

			&.col-checkbox {
				text-align: center;

				input[type=checkbox] {
					margin-top: 1em;
				}
			}
		}
	}
}