/*!
 * Font Awesome Pro 5.5.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
 
@import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';

// @font-face
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/solid';
