/* ========================================================================== */
/* NOTIFICATIONS */
/* Notifications component styles */
/* ========================================================================== */

/* Notifications component */
.notifications
{
	@include position-absolute($notifications-top, -$notification-w, 0);
	z-index:3;
	width: $notification-w;
	display: flex;
	flex-direction: column;
	background: $notifications-bg;
	box-shadow: $notifications-shadow;
	transition: $notifications-transition;

	.notif-opened &
	{
		right: 0;
	}
	
	&-head
	{
		@include flex-spacebetween(center);
		padding: $notifications-head-padding;

		h2 
		{
			margin-bottom: 0;
			font-size: $font-size-xl;
		}
	}

	&-list
	{
		flex: 1;
		overflow: auto;
	}

	.btn-filter:not(.active) 
	{
		background: $notifications-filters-bg;
	}
}

/* Notification item component */
.notification-item
{
	margin-bottom: 0;
	border: 0;
	border-bottom: $notification-item-border;
	border-radius: 0;
	cursor: pointer;

	&-read, &:hover
	{
		background: $gray-100;
	}

	&-content-head
	{
		@include flex-spacebetween(center);
		width: 100%;
		margin-bottom: $spacing-xs;
	}

	&-rate-label, &-rate-value, &-link
	{
		font-size: $font-size-sm;
	}

	&-rate-label
	{
		color: $gray-300;
		text-transform: uppercase;
	}

	.name
	{
		margin-right: $notification-item-name-margin;
	}
}

/* Notifications component */
.side-panel
{
	@include position-absolute($notifications-top, -$notification-w, 0);
	z-index:3;
	width: $notification-w;
	display: flex;
	flex-direction: column;
	background: $notifications-bg;
	box-shadow: $notifications-shadow;
	transition: $notifications-transition;

	&.opened
	{
		right: 0;
	}
	
	.notifications
	{
		&-head
		{
			@include flex-spacebetween(center);
			padding: $notifications-head-padding;

			h2 
			{
				margin-bottom: 0;
				font-size: $font-size-xl;
			}
		}

		&-list
		{
			flex: 1;
			overflow: auto;

			&-scroll {
				height: calc(100vh - 150px);
			}
		}

		.btn-filter:not(.active) 
		{
			background: $notifications-filters-bg;
		}
	}
}
